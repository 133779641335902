<template>
  <div class="main user-layout-register ant-card" :style="{top:(screenHeight/2-240)+'px'}">
    <div style="text-align: center;font-weight: bold;font-size: 1.2rem;line-height: 48px;">{{ $t('user.reg') }}</div>
    <a-form ref="formRegister" :form="form" id="formRegister">
      <a-form-item>
        <a-input
          size="large"
          type="text"
          :placeholder="$t('user.name')"
          v-decorator="['name', {rules: [{ required: true, message: $t('user.name.tip') }], validateTrigger: ['change', 'blur']}]"
        ></a-input>
      </a-form-item>
      <a-form-item>
        <a-input
          size="large"
          type="text"
          :placeholder="$t('user.email')"
          v-decorator="['email', {rules: [{ required: true, type: 'email', message: $t('user.email.tip') }], validateTrigger: ['change', 'blur']}]"
        ></a-input>
      </a-form-item>
      <a-row :gutter="16">
        <a-col class="gutter-row" :span="14">
          <a-form-item>
            <a-input size="large" type="text" :placeholder="$t('user.emailCaptcha')" v-decorator="['captcha', {rules: [{ required: true, message: $t('user.emailCaptcha.tip') }], validateTrigger: 'blur'}]">
              <a-icon slot="prefix" type="mail" :style="{ color: 'rgba(0,0,0,.25)' }"/>
            </a-input>
          </a-form-item>
        </a-col>
        <a-col class="gutter-row" :span="10">
          <a-button
            class="getCaptcha"
            size="large"
            :disabled="state.smsSendBtn"
            @click.stop.prevent="getCaptcha"
            v-text="!state.smsSendBtn && $t('user.getCaptcha')||(state.time+' s')"></a-button>
        </a-col>
      </a-row>

      <a-popover placement="rightTop" trigger="click" :visible="state.passwordLevelChecked">
        <template slot="content">
          <div :style="{ width: '240px' }" >
            <div :class="['user-register', passwordLevelClass]">つよさ：<span>{{ passwordLevelName }}</span></div>
            <a-progress :percent="state.percent" :showInfo="false" :strokeColor=" passwordLevelColor " />
            <div style="margin-top: 10px;">
              <span>{{ $t('user.password.level.tip') }}</span>
            </div>
          </div>
        </template>
        <a-form-item>
          <a-input
            size="large"
            type="password"
            @click="handlePasswordInputClick"
            autocomplete="false"
            :placeholder="$t('user.password.format.tip')"
            v-decorator="['password', {rules: [{ required: true, message: $t('user.password.format.tip')}, { validator: this.handlePasswordLevel }], validateTrigger: ['change', 'blur']}]"
          ></a-input>
        </a-form-item>
      </a-popover>

      <a-form-item>
        <a-input
          size="large"
          type="password"
          autocomplete="false"
          :placeholder="$t('user.password.change.passwordReview')"
          v-decorator="['password2', {rules: [{ required: true, message: $t('user.password.format.tip') }, { validator: this.handlePasswordCheck }], validateTrigger: ['change', 'blur']}]"
        ></a-input>
      </a-form-item>

      <a-form-item>
        <a-button
          size="large"
          type="primary"
          htmlType="submit"
          class="register-button"
          :loading="registerBtn"
          @click.stop.prevent="handleSubmit"
          :disabled="registerBtn">{{ $t('user.reg') }}
        </a-button>
        <router-link class="login" :to="{ name: 'login' }">{{ $t('user.account.login') }}</router-link>
      </a-form-item>

    </a-form>
  </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin.js'
import { getEmailCaptcha, reg } from '@/api/login'
import md5 from 'md5'
import axios from 'axios'

let levelNames = {

}
const levelClass = {
  0: 'error',
  1: 'error',
  2: 'warning',
  3: 'success'
}
const levelColor = {
  0: '#ff0000',
  1: '#ff0000',
  2: '#ff7e05',
  3: '#52c41a'
}
export default {
  name: 'Register',
  components: {
  },
  mixins: [mixinDevice],
  created () {
    this.screenHeight = document.body.clientHeight
    levelNames = {
      0: this.$t('user.password.level.low'),
      1: this.$t('user.password.level.low'),
      2: this.$t('user.password.level.medium'),
      3: this.$t('user.password.level.high')
    }
  },
  data () {
    return {
      form: this.$form.createForm(this),
      screenHeight: 0,
      state: {
        time: 60,
        smsSendBtn: false,
        passwordLevel: 0,
        passwordLevelChecked: false,
        percent: 10,
        progressColor: '#FF0000'
      },
      registerBtn: false,
      msgId: null
    }
  },
  computed: {
    passwordLevelClass () {
      return levelClass[this.state.passwordLevel]
    },
    passwordLevelName () {
      return levelNames[this.state.passwordLevel]
    },
    passwordLevelColor () {
      return levelColor[this.state.passwordLevel]
    }
  },
  beforeRouteLeave (to, from, next) {
    Object.assign(this.state, {
      passwordLevelChecked: false
    })
    next()
  },
  methods: {

    handlePasswordLevel (rule, value, callback) {
      let level = 0

      // 判断这个字符串中有没有数字
      if (/[0-9]/.test(value)) {
        level++
      }
      // 判断字符串中有没有字母
      if (/[a-zA-Z]/.test(value)) {
        level++
      }
      // 判断字符串中有没有特殊符号
      if (/[^0-9a-zA-Z_]/.test(value)) {
        level++
      }
      this.state.passwordLevel = level
      this.state.percent = level * 30
      if (level >= 2) {
        if (level >= 3) {
          this.state.percent = 100
        }
        callback()
      } else {
        if (level === 0) {
          this.state.percent = 10
        }
        callback(new Error(this.$t('user.password.level.error')))
      }
    },

    handlePasswordCheck (rule, value, callback) {
      const password = this.form.getFieldValue('password')
      console.log('value', value)
      if (value === undefined) {
        callback(new Error(this.$t('user.password.change.password.tip')))
      }
      if (value && password && value.trim() !== password.trim()) {
        callback(new Error(this.$t('user.password.change.password.not.match')))
      }
      callback()
    },

    handlePhoneCheck (rule, value, callback) {
      console.log('handlePhoneCheck, rule:', rule)
      console.log('handlePhoneCheck, value', value)
      console.log('handlePhoneCheck, callback', callback)

      callback()
    },

    handlePasswordInputClick () {
      if (!this.isMobile()) {
        this.state.passwordLevelChecked = true
        return
      }
      this.state.passwordLevelChecked = false
    },

    handleSubmit () {
      const _this = this
      const { form: { validateFields }, $router } = this
      validateFields({ force: true }, (err, values) => {
        if (!err) {
          axios.post('/user/reg', {
            appid: 'com.reflex.alc.manager.net',
            name: values.name,
            msgId: this.msgId,
            verifyCode: values.captcha,
            email: values.email,
            account_type: 1,
            password: md5(values.password)
          }).then(resp => {
            const res = resp.data
            if (res.code === 0) {
              this.$notification.success({
                message: this.$t('user.reg.success'),
                description: this.$t('user.reg.success.description'),
                duration: 2000
              })
              setTimeout(function () {
                _this.$router.push({ name: 'login' })
              }, 1500)
            } else {
              this.$notification.error({
                message: this.$t('user.reg.fail'),
                description: res.msg.ja
              })
            }
          })
        }
      })
    },

    getCaptcha (e) {
      e.preventDefault()
      const { form: { validateFields }, state, $message, $notification } = this

      validateFields(['email'], { force: true },
        (err, values) => {
          if (!err) {
            state.smsSendBtn = true

            const interval = window.setInterval(() => {
              if (state.time-- <= 0) {
                state.time = 60
                state.smsSendBtn = false
                window.clearInterval(interval)
              }
            }, 1000)

            const hide = $message.loading(this.$t('user.email.captcha.send'), 0)

            axios.post('/user/verify/email/send/v2', { email: values.email, action: 'reg' }).then(res => {
             const resp = res.data
              setTimeout(hide, 200)
              if (resp.code === 0) {
                this.msgId = resp.data
              } else {
                this.requestFailed(resp)
              }
            }).catch(err => {
              setTimeout(hide, 1)
              clearInterval(interval)
              state.time = 60
              state.smsSendBtn = false
              this.requestFailed(err)
            })
          }
        }
      )
    },
    requestFailed (err) {
      this.$notification['error']({
        message: 'Error',
        description: (err && err.msg && err.msg.ja) ? err.msg.ja : 'There was an error with the request, please try again later.',
        duration: 4
      })
      this.registerBtn = false
    }
  },
  watch: {
    'state.passwordLevel' (val) {
      console.log(val)
    }
  }
}
</script>
<style lang="less">
  .user-register {

    &.error {
      color: #ff0000;
    }

    &.warning {
      color: #ff7e05;
    }

    &.success {
      color: #52c41a;
    }

  }

  .user-layout-register {
    .ant-input-group-addon:first-child {
      background-color: #fff;
    }
  }
</style>
<style lang="less" scoped>
.main{
  background: rgba(255,255,255,0.9);
  padding: 10px 30px 20px 30px;
  backdrop-filter: blur(15px);
  border-radius: 8px;
  position: absolute;
  width: 442px !important;
  right:100px;

  .title{
    text-align: center;
    line-height: 80px;
    font-weight: bold;
    font-size: 1.2rem;
  }
}
  .user-layout-register {

    & > h3 {
      font-size: 16px;
      margin-bottom: 20px;
    }

    .getCaptcha {
      display: block;
      width: 100%;
      height: 40px;
    }

    .register-button {
      width: 50%;
    }

    .login {
      float: right;
      line-height: 40px;
    }
  }
</style>
